@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@900&display=swap");

:root {
  --font-primary: "Noto Sans Georgian", sans-serif;
  --image-max-height: 30em;
  --header-height: 3em;

  --light-tone: #f0f0f0;
  --dark-tone: #b1b1b1;
  --text-color: #02062c;

  --primary-color: #007bff;
  --primary-hover-color: #0056b3;
  --secondary-color: #ffffff;
  --secondary-text-color: #007bff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  font-family: var(--font-primary);
  color: var(--text-color);
  min-height: 100vh;
  min-height: 100svh;
  background-color: var(--text-color);
}

h1 {
  font-weight: 900;
}

h2 {
  font-weight: 500;
}
