.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  
  justify-content: flex-start;
}

.scroll {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content > p {
  text-align: center;
  margin-bottom: 10px;
  line-height: 25px;
}

.header {
  text-align: center;
}

.tosText {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;

  padding-left: 0.5em;
}