/* Header.module.css */
.header {
  background-color: var(--text-color);
  width: 100%;
  color: #fff;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header a {
  color: white;
  text-decoration: none;
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.left a img {
  filter: invert(1);
}

.left,
.right {
  display: flex;
  gap: 1em;
}

.icon {
  height: 1em;
  min-height: 1em;
}
