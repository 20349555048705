.button {
  display: inline-block;
  padding: 0.75em 1.5em;
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.button:hover {
}

.button:active {
  transform: translateY(0);
}

.primary {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.primary:hover {
  background-color: var(--primary-hover-color);
}

.secondary {
  background-color: var(--secondary-color);
  color: var(--secondary-text-color);
  border: 2px solid var(--primary-color);
}

.secondary:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.disabled {
  pointer-events: none;
  cursor: pointer;
  opacity: 0.5;
}