.clearButton {
  margin: 0 1em;
  background-color: red !important;
}
.loading {
  opacity: 0.5;
}

@media(max-width: 1050px) {
  .clearButton {
    width: 100%;
    margin: 0;
  }
}