.container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  text-align: center;
  gap: 1em;
  width: 100%;
  align-items: center;

  padding: 2em;
  padding-top: 0em;
}

.titles {
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: var(--light-tone);
  padding: 1em;
  border-radius: 0.5em;
  border: 1px solid black;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.containerSubmitting {
  pointer-events: none;
  opacity: 0.5;
}

.content {
  margin-top: 5.5em;
  width: fit-content;
  display: flex;
  height: 100%;

  width: 80%;
  /* max-height: calc(var(--image-max-height) + 2em);
  max-width: calc(var(--image-max-width) + 2em); */
  padding-right: 0;
  padding: 1em;
  padding-right: 0;
  border-radius: 0.5em;
  background-color: var(--light-tone);
  text-align: center;
}

.right {
  flex: 1;
  width: 100%;
  max-width: 280px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
  /* max-height: var(--image-max-height); */
}

.attemptsWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  gap: 1em;
  /* padding: 1em 0; */

  padding: 0 1em;
}

.submitButton {
  margin: 0 1em;
  margin-top: 1em;
}

@media (max-width: 1500px) {
  .content {
    width: 90%;
  }
}

@media (max-width: 1300px) {
  .content {
    width: 95%;
  }
}

@media (max-width: 1050px) {
  .content {
    flex-direction: column;
    padding-right: 1em;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  .submitButton {
    margin: 0;
    margin-top: 1em;
  }

  .attemptsWrapper {
    max-height: 150px;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    /* max-width: calc(100% - 2em); */
    padding: 0;
  }

  .right {
    padding: 1em 0;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .titles > h2 {
    font-size: 20px;
  }
}

@media (max-width: 500px) {
  .titles > h2 {
    font-size: 16px;
  }
}
