.container {
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2em;

  justify-content: space-between;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 1em;
  padding-top: 0.5em;
  transition: background-color 0.3s ease-in-out;
}

/* .container:hover {
  background-color: #c4d4ff;
} */

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.header > h4, span {
  font-size: 12px;
  font-weight: normal;
}
.header > h4 {
  color: black;
}
.header > span {
  color: #666666;
}

.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.contentImage {
  width: 70px;
  height: 50px;
  object-fit: cover;
}

.coordinates {
  display: flex;
  gap: 0.2em;
}
.coordinates > p {
  font-size: 11px;
  color: #363636;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.delete {
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  
  display: flex;
  gap: 0.3em;
  align-items: center;
}

.delete > svg {
  height: 1.2em;
  width: 1.2em;
  color: red;
}

.delete > span {
  font-size: 10px;
  color: #363636;
}

.deleting {
  opacity: 0;
  transition: all 0.5s ease;
}

@media(max-width: 1050px) {
  .container {
    width: 220px;
    flex-shrink: 0; /* Prevent shrinking */
  }
}

/* @media (max-width: 768px) {
  .container {
    align-items: center;
    justify-content: center;
    gap: 1em;
    min-width: fit-content;
  }
} */
