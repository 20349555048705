.image {
  margin-top: var(--header-height);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  z-index: -1;
}

@media (max-width: 1050px) {
  .image {
    object-fit: contain;
  }
}
