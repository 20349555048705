.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
}

.content > p {
  text-align: center;
  margin-bottom: 10px;
  line-height: 28px;
}

.separator {
  flex: 1;
}

.buttonsContainer {
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.cancelButton {
  background-color: red;
}
.cancelButton:hover {
  background-color: red;
  opacity: 0.5;
}