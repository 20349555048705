.imageContainer {
  position: relative;
  flex: 1;
  width: calc(100% - 260px);
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor {
  position: absolute;
}

.answersSubmitted {
  pointer-events: none;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain;
  /* max-height: var(--image-max-height); */

  /* Make the image undraggable and unselectable (https://stackoverflow.com/a/12906840/15109015) */
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.attempt {
  position: absolute;
  transform: translate(-50%, -50%);

  pointer-events: none;

  width: 25px;
  height: 25px;

  /* Animation */
  transition: background-color 1s ease-out;
  background-color: rgba(241, 90, 34, 0);
}
.attemptHovered {
  -webkit-animation-duration: 1s;
  -webkit-animation-name: fading;
  animation-duration: 1s;
  animation-name: fading;
}

@-webkit-keyframes fading {
  from {
    background-color: rgba(241, 90, 34, 1);
  }
  to {
    background-color: rgba(241, 90, 34, 0);
  }
}
@keyframes fading {
  from {
    background-color: rgba(241, 90, 34, 1);
  }
  to {
    background-color: rgba(241, 90, 34, 0);
  }
}

@media (max-width: 1050px) {
  .imageContainer {
    width: 100%;
  }
}

@media (max-width: 750px) {
  .attempt {
    width: 15px;
    height: 15px;
  }
}

@media (max-width: 500px) {
  .attempt {
    width: 13px;
    height: 13px;
  }
}
